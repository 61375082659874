.view-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: safe center;
  overflow: auto;
  @extend .py-2;
}

.m-auto {
  margin: auto;
}

.logo {
  height: 70px !important;
  width: 30%;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px !important;
  margin-top: 60px;
  justify-content: center;
}

.payment-icon-content {
  display: flex;
  flex-direction: row;
}

.button-div.pad-bottom-large {
  padding-bottom: 50px !important;
}

.title-content {
  align-self: center;
}

.centered-icon {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 767px) {
  .logo {
    height: 65px;
    width: 60%;
  }
  .header {
    margin-top: 35px;
  }
}
