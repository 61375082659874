label {
  color: #c4c4c4;
  font-size: 13px;
}

.dark-input,
.dark-select {
  font-size: 15px;
  height: 40px;
  border-color: $dark !important;
  border-radius: 5px;
  box-shadow: none !important;
  &:focus {
    outline: none !important;
    border-color: $dark !important;
    box-shadow: none !important;
  }
}

.form-content {
  border-radius: 20px;
}
