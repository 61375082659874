@import './variables';
@import '~bootstrap/scss/bootstrap.scss';
@import 'App.scss';
@import 'Forms.scss';

html,
body {
  font-family: 'Signika', sans-serif;
}

body {
  margin: 0;
  background-color: $dark;
}

.form-content {
  flex-basis: 290px;
}

.bg-light-secondary {
  background-color: $lightSecondary;
}

.xxlarge-text {
  font-size: xx-large;
}

.xlarge-text {
  font-size: x-large;
}

.larger-text {
  font-size: larger;
}

.large-text {
  font-size: large;
}

.medium-text {
  font-size: medium;
}

.small-text {
  font-size: small;
}

.smaller-text {
  font-size: smaller;
}

.cursor-not-allowed{
  cursor: not-allowed !important;
}